import React, { Component } from 'react';

import Article from '../components/Article/Article';
import BaseLayout from '../components/Layouts/BaseLayout';
import SideBar from '../components/SideBar/SideBar';

export default class PostTemplate extends Component {
    render() {
        const { post } = this.props.data;
        return (
            <BaseLayout sidebar={<SideBar />}>
                <Article {...post} />
            </BaseLayout>
        );
    }
}

export const query = graphql`
    query currentPost($id: String!) {
        post: wordpressPost(id: { eq: $id }) {
            ...Article
        }
    }
`