import React from 'react';
import Link from 'gatsby-link';

import Author from './Author';
import Blocks from '../Blocks/Blocks';

import './Article.css';

export default ({ content, excerpt, title, slug, date, datetime, isSummary, author, acf }) => (
    <article className={`article article--${isSummary ? `summary`: `full`}`}>
        <header className="article__header">
            <div className="article__meta">
                <time dateTime={datetime}>{date}</time>
            </div>
            <h2 className="article__title">
                {isSummary ? (
                    <Link to={`/${slug}`} dangerouslySetInnerHTML={{ __html: title }} />
                ) : (
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                )}
            </h2>
            <Author name={author.name} />
        </header>
        <div className="article__body">
            {/* <div dangerouslySetInnerHTML={{ __html: isSummary ? excerpt : content }} /> */}
            {acf && acf.page_layout_post &&
                <Blocks layouts={acf.page_layout_post} />
            }
        </div>
    </article>
);

export const ArticleFragment = graphql`
    fragment Article on wordpress__POST {
        title
        content
        slug
        excerpt
        date(formatString: "MMMM Do YYYY @ HH:mm:ss")
        datetime: date
        author {
            name
        }
        acf {
            page_layout_post {
                __typename
                ... on WordPressAcf_text_block {
                    title
                    text
                }
                ... on WordPressAcf_image_block {
                    title
                    image {
                        title
                        source_url
                    }
                }
            }
        }
    }
`