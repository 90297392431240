import React from 'react';

import './Author.css';

export default ({ name, image, description }) => (
    <address className="author">
        {/* <img className="author__avatar" src={image} alt={`Image for ${name}`} /> */}
        <h3 className="author__name">{name}</h3>
        {/* <div dangerouslySetInnerHTML={{ __html: description }} /> */}
    </address>
);
